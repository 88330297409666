export const whyJoinUsData = [
    "ONE OF THE MOST TRUSTED BOOK IN WHOLE MARKET.",
    "AVAILABLE FOR 24 HOURS IN A DAY FOR ANY SERVICE.",
    "ANY ISSUE RESOLVED IN VERY SHORTEST PERIOD.",
    "WE PROVIDE CLIENT FRIENDLY SITES AND SERVICE.",
    "WE DEAL ONLY WITH PREMIUM QUALITY SITES",
    "ALL DOUBTS AND QUERIES SOLVED IN MINUTES.",
]

export const withdrawlRules = [
    "24/7 withdrawal services",
    "Payment will be done within 10 minutes",
    "No extra massage after submitting form",
    "We accept all UPI",
    "We cannot provide withdrawal when server down or tecnical issue",
    "Withdrawal from have to fill properly Exp. Beneficiary name , UPI I'd , IFSC code, account details, betting I'd name"
]

export const refillRules = [
    "Deposit done with payment details will only be valid",
    "Deposit payment accept 24/7",
    "Reffil will be done 10 minutes",
    `Waiting time for reffil<br/>
    MINIMUM 5 MINS<br/>
    MAXIMUM 10 MINS`,
    "Do not waste your time and ours by giving fake slip"
]

export const gamingRules = [
    "THERE IS A CHANCE OF 1-2% THAT SITE WILL BE DOWN DUE TO SOME REASON AT ANY POINT OF TIME.IN SUCH CASES ALL THE BETS WILL BE VALID.WE WILL NOT BE HELD RESPONSIBLE FOR SITE ERROR AT ANY POINT OF TIME.PROFIT/LOSS IN SUCH SCENARIOS WILL BE COMPLETELY VALID.",
    "CASINO SETTLING IS DONE BY THE SITE.SO ANY ERROR IN SETTLING WILL NOT BE OUR RESPONSIBILITY.PROFIT/LOSS IN SUCH CASES WILL TOTALLY BELONG TO THE CLIENT.",
    "IN CASE OF A BET NOT BEING SETTLED AND LEFT IN EXPOSURE.PLEASE WAIT FOR 48-72 HOURS FOR THE SETTLEMENT.IF IT DOES NOT GET SETTLED EVEN AFTER THAT PLEASE CONTACT US.",
]

export const joinButtonData = [
    {
        name: "Join Telegram",
        link: "https://t.me/MIRAJEXC",
        external: true
    },{
        name: "Bookie Panel Site",
        link: "/panel-site",
        external: false
    },{
        name: "Demo Site / Client Sites",
        link: "/client-site",
        external: false
    },{
        name: "Demo Videos",
        link: "https://wa.me/918306229230?text=Hi%20I%20want%20demo%20videos",
        external: true
    },{
        name: "New Id / Refill",
        link: "https://wa.me/918306229230?text=Hi%20I'm%20interested%20to%20buy%20I'd",
        external: true
    },{
        name: "Need Withdrawl",
        link: "https://wa.me/918306229230?text=Hi%20I%20want%20to%20withdraw%20from%20my%20I'd",
        external: true
    },{
        name: "User Screenshots",
        link: "https://wa.me/918306229230?text=Hi%20I%20want%20user%20screenshots",
        external: true
    }
]
 export const stats = [{
    icon: "fa-globe",
    value: 3,
    h_text: "Position In Market"
},{
    icon: "fa-user",
    value: "83000 +",
    h_text: "World-Wide Registered Punters"
},{
    icon: "fa-crown",
    value: "58000 +",
    h_text: "Total Active Punters"
},{
    icon: "fa-trophy",
    value: "43000 +",
    h_text: "Winners Every Month"
}]

export const customers_data = [
    {
        name: "Nawal",
        designation: "Customer",
        feedback: "Hello , my name is Nawal. This website is very amazing, here you can play cricket and many other games. I won 7 lakh rupees here, I got my payment within 10 minutes, I fulfilled my dreams with the help of this site."
    },{
        name: "Harsh",
        designation: "Customer",
        feedback: "My experience with this company was amazing.  The customer service was excellent, and the site were exactly what I asked for.'' ''I was very impressed with the service which I received from mirajexch.'' They went above and beyond to help me find the right online betting site. Thanks - Mirajexch.com"
    },{
        name: "Ayush",
        designation: "Customer",
        feedback: "I have never seen such a site, it is really amazing, I got a lot of help from this site, here I have won a lot of cash & rewards, the customer service of this site is very helpful, here I got a lot of facilities. Thank for mirajexch.com"
    }
]

export const demoSitesData = [
    {
        name: "http://juaexch.com",
        id: "MIRAJDEMO",
        pass: "Miraj1234",
        image: "jua.png"
    },{
        name: "http://world777.com",
        id: "MIRAJDEMO",
        pass: "Miraj1234",
        image: "world777.png"
    },{
        name: "http://skyexch.art",
        id: "MIRAJDEMO",
        pass: "Miraj1234",
        image: "skyexch.png"
    },{
        name: "http://ssexch.io",
        id: "MIRAJDEMO",
        pass: "Miraj1234",
        image: "sscheah.jpeg"
    },{
        name: "http://lotusbook247.com",
        id: "MIRAJDEMO",
        pass: "Miraj1234",
        image: "lotus.png"
    },{
        name: "http://diamondexch.com",
        id: "MIRAJDEMO",
        pass: "Miraj1234",
        image: "diamond.png"
    },{
        name: "http://tenexch.com",
        id: "MIRAJDEMO",
        pass: "Miraj1234",
        image: "tentExhg.png"
    },
]

export const panelSitesData = [
    {
        name: "Skyexch (PTH,NHK,PTU,INR)",
        domain: "Skyexch.art",
        image: "skyexch.png"
    },{
        name: "World7 (INR)",
        domain: "World7.com",
        image: "world777.png"
    },{
        name: "Tenexch (HKD,USD,INR)",
        domain: "Tenexch.com",
        image: "tentExhg.png"
    },{
        name: "SkyInPlay (INR)",
        domain: "Skyinplay.com",
        image: "skyexch.png"
    },{
        name: "Tajj777 (HKD,USD)",
        domain: "Tajj777.com",
        image: "taj.jpeg"
    },{
        name: "Diamondexch (INR)",
        domain: "Diamondexch.com",
        image: "diamond.png"
    },{
        name: "Juaexch (INR)",
        domain: "Juaexch.com",
        image: "jua.png"
    },{
        name: "Ssexch (HKD ,USD)",
        domain: "Ssexch.com",
        image: "sscheah.jpeg"
    },{
        name: "Lotusbook247 (USD ,HKD)",
        domain: "Lotusbook247.com",
        image: "lotus.png"
    },{
        name: "Silverexch (INR)",
        domain: "Silverexch.com",
        image: "silver.png"
    },{
        name: "Lordsexch (HKD, USD, INR)",
        domain: "Lordsexch.com",
        image: "lords.png"
    }
]