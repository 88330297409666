import React from 'react'

function Footer() {
  return (
    <footer>
        <div className="container">
            <div className="d-flex justify-content-center align-items-center flex-column">
                <h3 className='company_name'>MIRAJ ONLINE BOOK</h3>

                <div className='details'>
                    <span>Email: </span>
                    <a href='mailto:mirajexch@gmail.com'>mirajexch@gmail.com</a>
                </div>
                <div className='details'>
                    <span>Phone: </span>
                    <a href='tel:8306229230'>8306229230</a>
                </div>

                <div className='details'>
                    <img src='/assets/images/helpdesk.png' alt='helpdesk number' className='me-2' width={20}/>
                    <span>Helpdesk Number: </span>
                    <a href='tel:8306229230'>8000654718</a>
                    &nbsp;/&nbsp;
                    <span>Withdrawl: </span>
                    <a href='tel:8306229230'>6367734031</a>
                </div>

                <a className='my-4 theme_btn' href="https://wa.me/918306229230?text=Hi%20I'm%20interested%20to%20buy%20I'd" target='_blank'>Get your Id</a>

                <p className='copyright mt-3'>© Copyright MIRAJ ONLINE BOOK. All Rights Reserved</p>
            </div>
        </div>
    </footer>
  )
}

export default Footer