import React from 'react'

function ContactForm() {
  return (
    <div className='contact_us mt-4'>
        <h3 className='text-center heading'>CONTACT US</h3>
        <div className="container">
            <div className='py-5'>
                <div className='d-flex align-items-center mb-4'>
                    <div className='connection_area me-4'>
                        <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div>
                        <h4>Email:</h4>
                        <a href='mailto:mirajexch@gmail.com'>mirajexch@gmail.com</a>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='connection_area me-4'>
                        <i class="fa-brands fa-whatsapp"></i>
                    </div>
                    <div>
                        <h4>Whatsapp:</h4>
                        <a href="https://wa.me/918306229230?text=Hi%20I'm%20interested%20to%20buy%20I'd" target='_blank'>8306229230</a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default ContactForm